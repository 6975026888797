import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import Layout from "@utils/layout"
import SectionMarkets from "@organisms/sections/markets"

const MarketTemplate = ({ data }) => {
  const market = data.datoCmsMarket
  return (
    <Layout>
      <div data-datocms-noindex>
        <Section xlTop md>
          <HelmetDatoCms seo={market.seoMetaTags} />
          <Header md lg containerLarge>
            <Flex alignCenter>
              <Column half>
                <Heading html="h6" level="sh" strong gray>
                  Market Strategy
                </Heading>
                <Heading html="h1" level="h1" className="market-title">
                  {market.introTitle}
                </Heading>
                <Paragraph level="lg">{market.introContent}</Paragraph>
              </Column>
              <Column half>
                <HeaderImage>
                  <GatsbyImage
                    image={market.introImage.gatsbyImageData}
                    alt={market.introImage.alt}
                    title={market.introImage.title}
                  />
                </HeaderImage>
              </Column>
            </Flex>
          </Header>
        </Section>
        <Section>
          <Container lg containerLarge>
            <Flex>
              <Column twoThirds>
                <MarketContent
                  dangerouslySetInnerHTML={{
                    __html: market.content,
                  }}
                />
                <MarketFeatures>
                  <Heading level="h2" html="h2">
                    MoonClerk Helps {market.title} Like You:
                  </Heading>
                  {market.marketFeatures.map((feature, index) => (
                    <MarketFeature key={index}>
                      <Heading level="h4" html="h5">
                        {feature.title}
                      </Heading>
                      <Paragraph level="md">{feature.content}</Paragraph>
                    </MarketFeature>
                  ))}
                </MarketFeatures>
              </Column>
              <Column third></Column>
            </Flex>
          </Container>
        </Section>
        <SectionMarkets light />
      </div>
    </Layout>
  )
}

export default MarketTemplate

export const fetchMarketTemplate = graphql`
  query fetchMarketTemplate($slug: String!) {
    datoCmsMarket(slug: { eq: $slug }) {
      title
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      introTitle
      introContent
      introImage {
        alt
        title
        gatsbyImageData(
          width: 800
          layout: CONSTRAINED
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
        )
      }
      content
      marketFeatures {
        title
        content
      }
    }
  }
`

const Header = styled(Container)`
  border-bottom: var(--borderPrimary);
`
const HeaderImage = styled.div`
  background-color: var(--gray--00);
  border-radius: 4px;
  padding: var(--spacing--sm);
`

const MarketContent = styled.div`
  padding-bottom: var(--spacing--sm);
`

const MarketFeatures = styled.div``

const MarketFeature = styled.div`
  margin-bottom: var(--spacing--xs);

  &:first-of-type {
    margin-top: var(--spacing--xs);
  }
`
